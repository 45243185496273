/**FONTS -------------------------------*/
$font-primary: 'Roboto', sans-serif;
$font-light: 'Roboto', sans-serif;
$font-demi: 'Roboto', sans-serif;

/**COLORS -------------------------------*/
// $color-dark-grey: #212529;
// $color-pale-dark-grey: #f1f3f5; // text field background
// $color-battleship-grey: #687077; // Text field floated label innactive
// $color-white: #ffffff;
$font-color-main: #000;
$bg-color: #fff;
$border-color: #ccc;
$color-primary: #000;
$color-accent: #00f;

/**TYPOGRAPHY --------------------------*/
$font-size-primary: 16px;
$line-height-primary: 1.5;

$font-size-capture-level1: 24px;
$font-size-level1: 22px;
$font-size-level2: 20px;
$font-size-level3: 18px;
$font-size-level4: 14px;
$font-size-level5: 12px;

/**RESPONSIVENESS --------------------------*/
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Maximum breakpoint width.
// The maximum value is reduced by 0.02px to work around the limitations of
// `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(md, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $max: map-get($breakpoints, $name);
  @return if($max and $max > 0, $max - 0.02, null);
}
// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}
// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin text-crop($line-height: 1.3, $top-adjustment: 0px, $bottom-adjustment: 0px) {
  // Configured in Step 1
  $top-crop: 7;
  $bottom-crop: 10;
  $crop-font-size: 36;
  $crop-line-height: 1.2;

  // Apply values to calculate em-based margins that work with any font size
  $dynamic-top-crop: max(($top-crop + ($line-height - $crop-line-height) * ($crop-font-size / 2)), 0) / $crop-font-size;
  $dynamic-bottom-crop: max(($bottom-crop + ($line-height - $crop-line-height) * ($crop-font-size / 2)), 0) / $crop-font-size;

  // Mixin output
  line-height: $line-height;

  &::before,
  &::after {
    content: '';
    display: block;
    height: 0;
    width: 0;
  }

  &::before {
    margin-bottom: calc(-#{$dynamic-top-crop}em + #{$top-adjustment});
  }

  &::after {
    margin-top: calc(-#{$dynamic-bottom-crop}em + #{$bottom-adjustment});
  }
}
// Mixin generated at: http://text-crop.eightshapes.com/?

/* Usage Examples
  .my-level-1-heading-class {
      @include text-crop; // Will use default line height of 1.3
      font-size: 48px;
      margin: 0 0 0 16px;
  }

  .my-level-2-heading-class {
      @include text-crop; // Will use default line height of 1.3
      font-size: 32px; // Don't need to change any settings, will work with any font size automatically
      margin: 0 0 0 16px;
  }

  .my-body-copy-class {
      @include text-crop($line-height: 2); // Larger line height desired, set the line height via the mixin
      font-size: 16px;
  }

  // Sometimes depending on the font-size, the rendering, the browser, etc. you may need to tweak the output. 
  // You can adjust the top and bottom cropping when invoking the component using the $top-adjustment and $bottom-adjustment settings 
  
  .slight-adjustment-needed {
      @include text-crop($top-adjustment: -0.5px, $bottom-adjustment: 2px);
      font-size: 17px;
  }

  .dont-do-this {
      @include text-crop;
      font-size: 16px;
      line-height: 3; // DO NOT set line height outside of the mixin, the mixin needs the line height value to calculate the crop correctly
  }
*/
