@use '@angular/material' as mat;

// Fonts
$my-custom-level: mat.define-typography-level(
  $font-family: Roboto,
  $font-weight: 400,
  $font-size: 1rem,
  $line-height: 1,
  $letter-spacing: normal,
);

$my-custom-typography-config: mat.define-typography-config(
  $headline-1: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $headline-2: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $headline-3: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $headline-4: mat.define-typography-level(34px, 40px, 400),
  $headline-5: mat.define-typography-level(24px, 32px, 400),
  $subtitle-1: mat.define-typography-level(15px, 24px, 500, Roboto, 0.0067em),
  $subtitle-2: mat.define-typography-level(16px, 28px, 400, Roboto, 0.0094em),
  $body-1: mat.define-typography-level(14px, 20px, 400, Roboto, 0.0179em),
  $body-2: mat.define-typography-level(14px, 24px, 500, Roboto, 0.0179em),
  $caption: mat.define-typography-level(12px, 20px, 400, Roboto, 0.0333em),
  $button: mat.define-typography-level(14px, 14px, 500, Roboto, 0.0893em),
);

@include mat.core();

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #ffffff;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#13171c, 0);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#ffffff, 0.04);
$light-bg-alpha-12: rgba(#ffffff, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

$mat-primary: (
  50: #b3b3b3,
  100: #000000,
  200: #000000,
  300: #000000,
  400: #000000,
  500: #000000,
  600: #000000,
  700: #000000,
  800: #000000,
  900: #000000,
  contrast: (
    50: $dark-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ),
);
$theme-primary: mat.define-palette($mat-primary, 500);

$mat-accent: (
  50: #b3b3ff,
  100: #0000ff,
  200: #0000ff,
  300: #0000ff,
  400: #0000ff,
  500: #0000ff,
  600: #0000ff,
  700: #0000ff,
  800: #0000ff,
  900: #0000ff,
  contrast: (
    50: $dark-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ),
);
$theme-accent: mat.define-palette($mat-accent, 500);

$mat-warn: (
  50: #ffb3b3,
  100: #ff0000,
  200: #ff0000,
  300: #ff0000,
  400: #ff0000,
  500: #ff0000,
  600: #ff0000,
  700: #ff0000,
  800: #ff0000,
  900: #ff0000,
  contrast: (
    50: $dark-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ),
);

$theme-warn: mat.define-palette($mat-warn);

$light-theme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
  )
);
$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
  )
);

// Emit theme-dependent styles for common features used across multiple components.
@include mat.core-theme($light-theme);

// Generates styles for all systems configured in the theme. In this case, color styles
// and default density styles are generated. Density is in themes by default.
@include mat.all-component-themes($light-theme);

// .theme-alternate {
//   // @include angular-material-theme($altTheme);
//   @include mat.core-theme($dark-theme);
// }
.dark-theme {
  // Generates styles for all systems configured in the theme. In this case, color styles
  // and the default density styles are generated. **Note** that this is a problem because it
  // means that density styles are generated *again*, even though only the color should change.
  @include mat.all-component-colors($dark-theme);
}

// Emit styles for MatButton based on `$my-theme`. Because the configuration
// passed to `define-light-theme` omits typography, `button-theme` will not
// emit any typography styles.
// @include mat.button-theme($light-theme);

// Include the theme mixins for other components you use here.
@include mat.checkbox-density(-1);
@include mat.slide-toggle-density(-1);

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-mdc-raised-button,
.mat-mdc-stroked-button,
.mat-mdc-flat-button {
  padding: 0 1.15em;
  margin: 0; // margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';
  .mat-badge-content {
    font-family: 'Roboto';
  }
}

// Custom buttons
.mat-mdc-button,
.mat-mdc-outlined-button,
.mat-mdc-raised-button.mat-accent,
.mat-mdc-raised-button.mat-primary {
  font-family: Roboto;
  font-weight: 500;
  padding: 0 16px !important;
  font-size: 16px;
  height: 40px;
}

.mat-mdc-raised-button {
  box-shadow: 0 6px 12px 0 rgba(0, 0, 255, 0.16);
}

.mat-stepper-next {
  flex-direction: row-reverse;
}

.mat-mdc-outlined-button.mat-accent:not(:disabled) {
  border-color: #0000ff;
}

// Custom Tooltip arrow (TODO: create a shared tooltip component passing @arrow: true | false & arrowPos)
.mat-mdc-tooltip {
  position: relative;
  overflow: visible !important;

  &.right:after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: -8px;
    margin-top: -8px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid $dark-bg-tooltip;
  }
}

// Custom Tabs
.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  font-size: 16px;
  font-weight: 500;
  color: #13171c;
}

// Custom stepper
.mat-horizontal-stepper-header-container {
  padding: 8px 20px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #e8e8e8;
  pointer-events: none !important;

  .mat-horizontal-stepper-header {
    font-size: 9px;
    height: 20px;
    padding: 0;

    .mat-step-icon {
      width: 16px;
      height: 16px;
      background-color: #8b8b8b;
      margin-right: 8px;
    }

    .mat-step-label {
      font-size: 14px;
    }
  }
}

// Custom Menu
.mat-mdc-menu-panel {
  box-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
  // border-radius: 22px !important;
  max-width: 100% !important;

  .mat-mdc-menu-item {
    font-size: 14px;
  }
}

// Custom Paginator
.mat-mdc-paginator {
  font-size: 12px !important;
  padding-right: 32px !important;
  box-sizing: border-box;

  .mat-mdc-paginator-container {
    min-height: 42px;
  }
}

// Custom SnackBar
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  min-width: auto !important;
}
