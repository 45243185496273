/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';
@import 'variables';

html,
body {
  height: 100%;
  overflow: hidden;
}
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

/* login autocomplete for chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
/* EOF: login autocomplete for chrome */

/* Mav menu sub menus */
.nav-sub-menu {
  margin-left: 64px !important;
}
.rtl {
  .nav-sub-menu {
    margin-left: 0 !important;
    margin-right: 64px !important;
  }
}
/* EOF: Mav menu sub menus */

/* Sidebar shared styles */
.sidebar-section {
  height: 100%;
  width: 100%;

  .mat-drawer {
    width: 600px;

    .mat-drawer-inner-container {
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .empty-data {
        text-align: center;
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;
        flex: 1 1 100%;
        justify-content: center;
      }
    }
  }

  .header {
    min-height: 54px;
    border-bottom: 1px solid #dcdcdc;
    background: #f5f5f5;
    display: flex;
    align-items: center;
    flex: 0 0 54px;
    justify-content: space-between;

    h3 {
      margin: 0 32px;
      font-size: 18px;
      color: #a0a0a0;
      font-family: Roboto;
      font-weight: 500;
    }

    mat-icon.close {
      cursor: pointer;
      position: absolute;
      right: 32px;
      top: 15px;
    }
  }
}

// mat-icon.close {
//   cursor: pointer;
//   position: absolute;
//   right: 5px;
//   top: 5px;
// }
.rtl {
  .sidebar-section mat-icon.close {
    right: auto !important;
    left: 32px;
  }
  // mat-icon.close {
  //   right: auto !important;
  //   left: 5px;
  // }
}

#printContent {
  display: none;
}

@media print {
  * {
    overflow: visible !important;
  }
  .page {
    page-break-after: always;
  }
  #printContent {
    display: block;
  }

  body {
    overflow: auto;
  }

  #main,
  #menu {
    display: none;
  }
}
/* EOF: Sidebar shared styles */

/* Normal component header */
header.normal-header {
  padding: 32px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;

  h1 {
    margin: 0;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    color: $font-color-main;
    align-self: center;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .tool-bar {
    // TODO move this to styles
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .clearTableFilters {
      font-family: Roboto;
      color: blue;
      cursor: pointer;
      line-height: 40px;
      padding-right: 16px;
    }
  }
}
/* EOF: Normal component header */

/* Live Person chat tag */
.LPMcontainer {
  inset: 80% 0px auto auto !important;
}
/* EOF: Live Person chat tag */

/* Custom error snackbar */
.snackbar-error > div {
  background-color: red !important;
}
