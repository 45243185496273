@import 'variables';

.mat-mdc-form-field {
  width: 100%;
  // margin-bottom: 24px;

  .mat-mdc-form-field-icon-prefix {
    margin-left: 16px;
  }

  .mat-mdc-text-field-wrapper {
    background-color: white;
  }

  .mat-mdc-form-field-hint {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
  }

  .mat-mdc-form-field-infix {
    min-height: auto;
  }
}

// .form {

//   &__fieldset {

//     &--bordered {
//       padding: 12px 0;
//       border: 1px solid $color-light-sky-blue;
//       border-right: 0;
//       border-left: 0;
//     }

//     &--no-material {
//       margin-bottom: 35px;

//       .mat-error > {

//         .mat-error {
//           padding-top: 4px !important;
//         }
//       }
//     }
//   }

//   &__field-message {
//     display: block;
//     padding: 0 16px;
//     font-family: $font-primary;
//     font-size: 14px;
//     text-align: left;
//   }
// }

// .mat-mdc-form-field {
//   width: 100%;
//   // margin-bottom: 6px;
//   font-size: $font-size-primary;
//   letter-spacing: normal;

//   .mat-mdc-input-element,
//   .mat-mdc-select {
//     // height: 25px;
//   }
//   /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
//   .mat-input-element,
//   .mat-select,
//   .mat-form-field-label {
//     // padding: 0 16px;
//     // margin-top: -4px;
//     // font-family: $font-primary;
//     // font-size: $font-size-primary;
//     // box-sizing: border-box;

//     // &[type='password'] {
//     //   font-family: 'sans-serif';
//     // }

//     &:-webkit-autofill {
//       // box-shadow: 0 0 0 30px $color-pale-dark-grey inset !important;
//     }
//   }

//   // &:not(.mat-form-field-can-float) .mat-input-element,
//   // &:not(.mat-form-field-can-float) .mat-select {
//   //   margin-top: 0;
//   // }

//   &.mat-form-field-with-preffix,
//   &.mat-form-field-type-mat-select {
//     .mat-mdc-form-field-error > {
//       .mat-mdc-form-field-error {
//         padding-left: 54px;
//       }
//     }
//   }

//   // .mat-form-field-flex {
//   //   height: 60px;
//   //   background-color: #f1f3f5; // TODO: move to variables.scss
//   //   border-radius: 8px 8px 0 0;
//   // }
//   /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
//   .mat-form-field-flex > .mat-form-field-outline {
//     background-color: #fff;
//   }
//   /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
//   .mat-form-field-subscript-wrapper,
//   .mat-form-field-underline {
//     // position: static;
//   }

//   // .mat-form-field-prefix,
//   // .mat-form-field-suffix {
//   //   align-self: center;
//   //   // margin-top: 6px;
//   //   margin-top: -16px;
//   // }
//   /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
//   .mat-form-field-prefix {
//     margin-left: 16px;
//   }
//   /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
//   .mat-form-field-suffix {
//     // margin-right: 12px;
//     margin-right: 0;
//     // color: $color-blue-grey;
//   }
//   /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
//   .mat-form-field-underline {
//     // background-color: $color-light-grey-blue;
//     transition: height ease-out 0.3s;
//   }

//   .mat-icon {
//     // color: $color-dark-grey;
//   }

//   .select-arrow-icon {
//     // color: $color-light-grey-blue;
//   }

//   &.mat-form-field-appearance-legacy {
//     /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
//     .mat-form-field-underline {
//       height: 2px;
//       border-bottom: 1px solid transparent;
//       background-clip: padding-box;
//     }

//     &.mat-form-field-disabled {
//       /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
//       .mat-form-field-underline {
//         background-image: none;
//         background-color: rgba(0, 0, 0, 0.42);
//       }
//     }
//   }

//   &.mat-form-field-invalid {
//     /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
//     .mat-form-field-underline {
//       // border-color: $color-error;
//     }

//     + .form__field-message {
//       display: none;
//     }
//   }

//   &:hover {
//     /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
//     .mat-form-field-underline {
//       // border-color: $color-battleship-grey;
//       // background-color: $color-battleship-grey;
//     }
//   }
//   /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
//   /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
//   &.mat-form-field-should-float {
//     .mat-mdc-input-element {
//       // margin-top: 4px;
//     }

//     .mat-mdc-select {
//       // margin-top: 8px;
//     }
//     /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
//     .mat-form-field-label {
//       font-size: $font-size-level2;
//       // transform: translateY(-0.5em) translateX(0.2em) scale(0.75) perspective(100px) translateZ(0.001px) !important;
//     }
//     /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
//     .mat-select-arrow {
//       // margin-top: -15px;
//     }
//   }

//   + .form__field-message {
//     position: absolute;
//     top: 68px;
//   }
// }

// .mat-mdc-select-panel {
//   max-height: 350px !important;

//   .mat-mdc-option {
//     &.mat-mdc-selected {
//       &:not(.mat-mdc-option-multiple) {
//         background: none;
//         // color: $color-lightish-blue;
//       }
//     }
//   }
// }

// .mat-mdc-form-field-error {
//   display: block;
//   // font-family: $font-primary;
//   font-size: 14px;
//   text-align: left;
// }

// .mat-mdc-form-field-error > {
//   .mat-mdc-form-field-error {
//     padding: 0 16px;
//   }
// }

// // .form-captcha {

// //   .mat-error > {

// //     .mat-error {
// //       padding-top: 2px;
// //       padding-left: 54px;

// //       @include media-breakpoint-down(sm) {
// //         padding-left: 0;
// //         text-align: center;
// //       }
// //     }
// //   }
// // }

// // .StripeElement {
// //   position: relative;
// //   height: 60px;
// //   padding: 20px 14px 14px;
// //   background-color: $color-pale-dark-grey;
// //   border-bottom: 1px solid $color-light-grey-blue;
// //   border-radius: 8px 8px 0 0;

// //   &:hover,
// //   &--focus,
// //   &--invalid {
// //     border-bottom-width: 2px;
// //     border-bottom-color: $color-battleship-grey;
// //   }

// //   &--focus,
// //   &--focus:hover {
// //     border-bottom-color: $color-lightish-blue;
// //   }

// //   &--invalid,
// //   &--invalid:hover {
// //     border-bottom-color: $color-error;
// //   }
// // }

// .mat-mdc-slide-toggle {
//   /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
//   .mat-slide-toggle-ripple {
//     &.mat-ripple {
//       display: none;
//     }
//   }

//   &.mat-primary.mat-checked {
//     /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
//     .mat-slide-toggle-thumb {
//       // background-color: $color-lightish-blue;
//     }
//   }
// }

// /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
// /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
// mat-select {
//   /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
//   .mat-select-arrow {
//     // opacity: 0;
//   }
// }

// /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
// .mat-select-value {
//   padding-left: 0;
//   // color: $color-dark-grey;
// }

// /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
// mat-option.mat-selected {
//   // color: $color-lightish-blue!important;
//   // background: $color-white!important;

//   &:hover {
//     background: rgba(0, 0, 0, 0.04) !important;
//   }
// }

// /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
// mat-option.mat-active {
//   // background: $color-white!important;

//   &:hover {
//     background: rgba(0, 0, 0, 0.04) !important;
//   }
// }

// /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
// mat-option.mat-mdc-option {
//   // font-family: $font-primary;
// }

// .mat-placeholder-required {
//   display: none;
// }

// input[type=number]::-webkit-inner-spin-button,
// input[type=number]::-webkit-outer-spin-button {
//   -moz-appearance: none;
//   appearance: textfield;
//   -webkit-appearance: none;
//   margin: 0;
//   opacity: 0;
// }

// input[type=number] {
//   -moz-appearance: textfield;
//   appearance: textfield;
//   margin: 0;
// }

// label.shipa-custom-label {
//   position: absolute;
//   padding: 0 16px;
//   font-family: $font-primary!important;
//   color: rgba($color-black, 0.54);
//   font-size: 19px;
// }
